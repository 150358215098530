import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';

import { ProvidedComponent } from '../../provided/provided.component';
import { ListComponent } from '../list/list.component';

import { TableComponent } from './table.component';
import { TableConfig, TableData, TableRow } from './table.model';
import { TableProvider } from './table.provider';

@Component({
  standalone: true,
  selector: 'app-provided-table',
  template: `
    @if (provider) {
      <app-table
        [loading]="provider.loading()"
        [title]="config().title"
        [titleIcon]="config().titleIcon"
        [headerHidden]="config().headerHidden ?? false"
        [outerBordered]="config().outerBordered ?? false"
        [bordered]="config().bordered ?? false"
        [tableClass]="config().tableClass ?? ''"
        [filters]="config().filters ?? []"
        [filtersPlacement]="config().filtersPlacement ?? 'above'"
        [columns]="config().columns ?? []"
        [pageSizeOptions]="config().pageSizeOptions ?? [10, 20, 30, 40, 50]"
        [pageSizeHidden]="config().pageSizeHidden ?? false"
        [selectable]="config().selectable ?? false"
        [expandable]="config().expandable ?? false"
        [maxSelection]="config().maxSelection"
        [rowClickStrategy]="config().rowClickStrategy"
        [rows]="data().rows ?? []"
        [filter]="data().filter ?? {}"
        [sort]="data().sort ?? {}"
        [selected]="data().selected ?? []"
        [pagination]="data().pagination"
        (sortChange)="provider.sort.set($event)"
        (filterChange)="provider.setFilter($event)"
        (selectedChange)="provider.selected.set($event)"
        (paginationChange)="provider.pagination.set($event)"
        (rowClick)="handleRowClick($event)"
      ></app-table>
    }
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [TableComponent, ListComponent],
})
export class ProvidedTableComponent<Row extends TableRow>
  extends ProvidedComponent<
    TableConfig<Row>,
    TableData<Row>,
    TableProvider<Row, TableConfig<Row>, TableData<Row>>
  >
  implements OnInit
{
  handleRowClick(selected: string) {
    this.provider.handleRowClick(selected);
  }
}
