@if (filtersPlacement() === 'above' && filters().length) {
  <app-filter
    class="ehr-table__filter"
    [filter]="filter()"
    [fields]="filters()"
    (filterChange)="filterChange.emit($event)"
  ></app-filter>
}

<div
  class="ehr-table__wrapper"
  [class.ehr-table__wrapper--has-title]="!!title()"
>
  @if (title(); as title) {
    <div class="ehr-table__title">
      @if (titleIcon(); as icon) {
        <span nz-icon [nzType]="icon" style="width: 20px; height: 20px"></span>
      }
      <app-title level="4" [message]="title"></app-title>
    </div>
  }

  @if (filtersPlacement() === 'afterTitle' && filters().length) {
    <app-filter
      class="ehr-table__filter ehr-table__filter--after-title"
      [filter]="filter()"
      [fields]="filters()"
      (filterChange)="filterChange.emit($event)"
    ></app-filter>
  }

  <nz-table
    nzTemplateMode
    [nzLoading]="loading()"
    [nzOuterBordered]="outerBordered()"
    [nzBordered]="bordered()"
    [class]="tableClass()"
  >
    @if (!headerHidden()) {
      <thead>
        <tr>
          @if (expandable()) {
            <th nzWidth="50px"></th>
          }

          @if (displaySelectable()) {
            <th class="ehr-table__row--left-sticky" nzWidth="48px">
              <label
                nz-checkbox
                [nzDisabled]="checkDisabled()"
                [nzIndeterminate]="indeterminateAll()"
                [nzChecked]="checkedAll()"
                (nzCheckedChange)="handleCheckedAll($event)"
              ></label>
            </th>
          }

          @for (column of columns(); track column.id) {
            <th
              [nzShowSort]="column.sortable"
              [nzSortOrder]="sort()[column.sortIndex ?? column.id]"
              (nzSortOrderChange)="handleSort($any($event), column)"
              [style.white-space]="
                column.cellConfig?.noWrapHeader ? 'nowrap' : null
              "
              [class.ehr-table__row--right-sticky]="
                column.cellConfig?.stickyRight ?? false
              "
              [class.ehr-table__row--left-sticky]="
                column.cellConfig?.stickyLeft ?? false
              "
              [style.width.px]="column.cellConfig?.width"
              [style.max-width.px]="column.cellConfig?.width"
              [style.left.px]="column.cellConfig?.left"
            >
              {{ column.name | crmTranslate }}
            </th>
          }
        </tr>
      </thead>
    }

    <tbody>
      @if (!loading() && rows().length === 0) {
        <tr>
          <td
            [colSpan]="
              columns().length + (expandable() || displaySelectable() ? 1 : 0)
            "
          >
            <nz-empty></nz-empty>
          </td>
        </tr>
      }

      @for (row of resolvedRows(); track row.hash) {
        <tr
          class="ehr-table__row"
          [class.ehr-table__row--expanded]="row.isExpanded"
          [class.ehr-table__row--selected]="row.isSelected"
          (click)="handleRowClick($event, row)"
        >
          @if (expandable() && row.expandableContent) {
            <td class="ehr-table__row--expand">
              <i nz-icon nzType="icons:right-line"></i>
            </td>
          }

          @if (displaySelectable()) {
            <td
              class="ehr-table__row--left-sticky ehr-table__row__cell-checkbox"
              (click)="killEvent($event); toggleSelectedRow(row)"
            >
              <label
                (click)="killEvent($event)"
                nz-checkbox
                [nzDisabled]="checkDisabled() && !row.isSelected"
                [nzChecked]="row.isSelected"
                (nzCheckedChange)="toggleSelectedRow(row)"
              ></label>
            </td>
          }

          @for (column of columns(); track column.id) {
            <td
              [style.white-space]="column.cellConfig?.noWrap ? 'nowrap' : null"
              [class.ehr-table__row--right-sticky]="
                column.cellConfig?.stickyRight ?? false
              "
              [class.ehr-table__row--left-sticky]="
                column.cellConfig?.stickyLeft ?? false
              "
              [style.width.px]="column.cellConfig?.width"
              [style.max-width.px]="column.cellConfig?.width"
              [style.left.px]="column.cellConfig?.left"
            >
              @if (column | crmIsRenderItem) {
                <ng-container
                  [static]="true"
                  [crmRenderer]="column"
                  [additionalContext]="{ row }"
                ></ng-container>
              } @else {
                {{ (column | displayColumnData: row) || '-' }}
              }
            </td>
          }
        </tr>

        @if (row.expandableContent && row.isExpanded) {
          <tr class="ehr-table__row--expanded-content">
            <td></td>
            <td [colSpan]="columns().length">
              <ng-container
                [crmRenderer]="row.expandableContent"
                [additionalContext]="{
                  data: row | crmOmit: 'expandableContent',
                }"
              ></ng-container>
            </td>
          </tr>
        }
      }
    </tbody>
  </nz-table>

  @if (!(pagination()?.total | isNil)) {
    <crm-table-pagination
      [pagination]="pagination()"
      [pageSizeChangeHidden]="pageSizeHidden()"
      [pageSizeOptions]="pageSizeOptions()"
      (paginationChanged)="paginationChange.emit($event)"
    ></crm-table-pagination>
  }
</div>
